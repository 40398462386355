#work {
    min-height: 100vh;
}

#htlo, #typography, #kinematix {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.placeholder p {
    padding: 50px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
}

.work-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 20;
    margin-bottom: 50px;
    flex-wrap: wrap;
}

#work button {
    background: none;
    border: none;
    color: var(--interact);
    font-family: inherit;
    text-transform: uppercase;
    transition-duration: 200ms;
    cursor: pointer;
    font-weight: 600;
    font-size: 26px;
}

#work button:hover {
    color: var(--active);
}

.work-img {
    width: 100%;
}

.tall-img {
    width: 40%;
    margin: 0 auto;
}

.work-subtitle {
    margin: 10px 0 60px 0;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--text);
}

.back-to-top {
    width: fit-content;
    margin: 50px auto 20px auto;
}

.back-to-top button {
    margin: 0 auto;
    width: fit-content;
}

/** KINEMATIX **/

#kinematix .section {
    width: 100%;
    background-color: var(--bg2);
    display: flex;
    margin-bottom: 50px;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
}

#kinematix .section h1.section-header {
    margin: 0;
    text-transform: uppercase;
    font-size: 30px;
    margin-right: 20px;
}

#kinematix .section .color-scheme {
    display: flex;
    width: fit-content;
    align-items: center;
}

#kinematix .section .color-scheme .color {
    width: 30px;
    height: 30px;
}

#kinematix .section .color-scheme p {
    margin-left: 20px;
}

img.summer-workshop-big {
    width: 49%
}

.big-summer-workshops {
    display: flex;
    justify-content: space-between;
}

img.summer-workshop-small {
    width: 23.5%;
}

.small-summer-workshops {
    margin-top: 2%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
}

img.summer-workshop-small-fb {
    width: 32%
}

.ebo-box {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 20px;
}

.ebo-box:hover .ebo-img, .september-box:hover .september-img, .bim-box:hover .bim-img {
    scale: 0.8;
}

.ebo-img {
    width: 25%;
    transition-duration: 200ms;
}

.september-box {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    justify-content: center;
}

.september-img {
    width: 33%;
    transition-duration: 200ms;
}

.bim-box {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.bim-img {
    width: 33%;
    transition-duration: 200ms;
}

@media screen and (max-width: 720px) {
    img.summer-workshop-big {
        width: 100%;
        margin-bottom: 4%;
    }

    .big-summer-workshops {
        flex-direction: column;
    }

    img.summer-workshop-small {
        width: 48%;
        margin-bottom: 4%;
    }

    .small-summer-workshops {
        flex-wrap: wrap;
        margin-top: 0%;
    }
}

/**Architecture**/
#fph {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 120px;
}

.fph-top {
    width: 65%;
    height: auto;
    object-fit: cover;
}

.fph-small-pictures {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fph-small-pictures img {
    width: 100%;
}

#plum {
    margin-top: 150px;
    margin-bottom: 30px;
}

.plum-small-pictures {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.plum-small-pictures img {
    width: 25%;
}

.work-iframe {
    width: 100%;
    height: 500px;
}

@media screen and (max-width: 600px) {
    #fph {
        flex-direction: column;
    }

    .fph-top {
        width: 100%;
        margin-bottom: 4%;
    }

    .fph-small-pictures {
        flex-direction: row;
        width: 100%;
    }

    .fph-small-pictures img {
        width: 32%
    }
}

@media screen and (max-width: 900px) {
    #fph, #plum {
        margin-top: 20%;
    }

    .plum-small-pictures {
        margin-top: 4%;
    }
}


/**Typography**/

.codec {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.codec img {
    width: 49%;
}

.summer-sessions {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 100px;
}

.ica-ss-img {
    width: 100%;
    margin: 0 auto 30px auto;
    max-width: 650px;
}

#form-counterform {
    padding-top: 30px;
}

.form-counterform-grid {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto auto auto;
}

.form-counterform-letter {
    width: 100%;
    height: auto;
}

.form-counterform-grids-container {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.grid-and-caption {
    width: 30%;
}

.grid-caption {
    color: var(--active);
    margin: 10px 0 0 0;
}

@media screen and (max-width:640px) {
    .form-counterform-grids-container, .codec {
        flex-direction: column;
    }

    .codec img {
        width: 100%;
        margin-bottom: 5%;
    }

    .grid-and-caption {
        width: 100%;
        margin-bottom: 40px;
    }
}


#momo {
    background-color: var(--bg);
    padding-top: 100px;
    transition-duration: 200ms;
    width: 100%;
    max-width: 800px;
    margin: 0 auto 100px auto;
    color: var(--text);
}

.momo-title {
    display: flex;
    width: 100%;
}

.momo-header-letter {
    width: 25%;
    color: var(--active);
}

.momo-header .top, .momo-header .bottom {
    display: flex;
    margin-top: 10px;
}

.momo-subtitle {
    margin-top: 30px;
}

.momo-subtitle-letter {
    width: 35px;
    height: 35px;
    margin-right: 2px;
    color: var(--text);
}

.momo-grid {
    margin-top: 80px;
    display: grid;
}

.momo-list {
    margin-top: 15px; 
    display: grid;
    grid-template-columns: repeat(13, 7.69%);
    gap: 10% 0%;
}

.momo-list-letter {
    height: 90%;
    width: 90%;
}

.momo-grid-letter {
    height: 92%;
    width: 92%;
    margin: auto auto;
}

.momo-grid-g {
    grid-column: 3/5;
    grid-row: 2/4;
}

.momo-grid-j {
    grid-column: 1/3;
    grid-row: 4/6;
}

.momo-blurb-1 {
    grid-column: 3/5;
}

.momo-grid-r {
    grid-column: 2/4;
    grid-row: 7/9;
}

.momo-blurb-2 {
    grid-column: 1/3;
}

.momo-grid-x {
    grid-column: 1/3;
    grid-row: 10/12;
}

.momo-grid p {
    margin: 0;
}

.momo-grid-big {
    width: 96%;
    height: 96%;
    margin: auto auto;
    color: var(--interact);
    transition-duration: 200ms;
}

.momo-grid-lines {
    opacity: 0%;
}

.momo-grid-g:hover + .momo-grid-g.momo-grid-lines, 
.momo-grid-g.momo-grid-lines:hover,
.momo-grid-j:hover + .momo-grid-j.momo-grid-lines,
.momo-grid-j.momo-grid-lines:hover,
.momo-grid-r:hover + .momo-grid-r.momo-grid-lines,
.momo-grid-r.momo-grid-lines:hover, 
.momo-grid-x:hover + .momo-grid-x.momo-grid-lines,
.momo-grid-x.momo-grid-lines:hover {
    opacity: 100%;
}

.momo-blurb-3 {
    grid-column: 3/5;
}

.momo-grid p {
    margin: auto 10%;
    text-align: center;
    font-size: 15px;
    line-height: 18px;
    height: auto;
    color: var(--active);
}

@media screen and (max-width: 700px){
    .momo-grid p{
        font-size: 1.5vw;
        line-height: 1.8vw;
    }
}

.big-bodoni {
    width: 100%;
}

.big-bodoni img {
    width: 100%;
    height: auto;
    margin: 3vw 0 0 0;
}

.mini-bodonis {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.mini-bodonis img {
    width: 30%;
    height: auto;
}