#projects .heading {
    font-size: 180px;
}

.all-projects {
    margin-top: 150px;
}

@media screen and (max-width: 1000px) {
    #projects .heading {
        font-size: 18vw;
        line-height: 16vw;
        top: -3.5vw;
    }
}

.project h2 {
    margin: 0;
    transition-duration: 200ms;
}

.project {
    width: 100%;
    margin-bottom: 200px;
}

.project .project-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
}

.project .project-header .project-title {
    text-transform: uppercase;
    color: var(--text);
    font-size: 40px;
    line-height: 40px;
    margin-right: 50px;
}

.project .project-subheader {
    margin-bottom: 20px;
    font-size: 25px;
    line-height: 32px;
    font-weight: 200;
    font-style: italic;
}

.project iframe {
    width: 100%;
    height: 600px;
    zoom: 1;
    border: 2px solid var(--text);
    background-color: white;
}

@media screen and (max-width: 1000px) {
    .project iframe {
        display: none;
    }

    .project-links {
        flex-direction: column;
    }

    .project-link {
        margin-top: 20px;
    }

    .project p {
        margin-bottom: 0;
    }
}

.project p {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
}

.project-links {
    display: flex;
    width: 100%;
}

.project-link-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.project-link-text {
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
}

.project-link {
    display: flex;
    align-items: center;
    margin-right: 50px;
}