#footer {
    color: #aaaaaa;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 80px;
}

.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    border-top: 2px solid #aaaaaa;
}

.footer-section {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}


#footer svg {
    height: 20px;
}


#footer p, #footer a, #footer h1 {
    font-size: 16px;
    color: inherit;
    margin:0 20px;
}

#footer h1 {
    font-weight: 800;
}

#footer p, #footer a {
    font-weight: 300;
}

#footer a:hover {
    color: var(--active);
}

#footer a {
    text-transform: lowercase;
}

#footer.hide {
    display: none;
}

@media screen and (max-width: 786px) {
    .footer-section {
        flex-direction: column;
    }

    .footer-section.sitemap {
        display: none;
    }
}