#about .heading {
    opacity: 15%;
    font-size: 300px;
    line-height: 280px;
    top: -70px;
}

#huntergroff {
    min-height: 100vh;
}

#huntergroff h3 {
    font-size: 140px;
    font-weight: 800;
    line-height: 100px;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    color: var(--active);
    min-width: 1200px;
}

#huntergroff h3.name{ 
    z-index: 2;
    position: absolute;
    top: 0px;
}

.about-headshot {
    width: 380px;
    height: 570px;
    border-radius: 0 0 60px 60px;
    margin: 0 40px;
    z-index: 5;
}

.blurbs-and-image .left-blurbs, .blurbs-and-image .right-blurbs {
    width: 300px;
}

.blurbs-and-image .left-blurbs {
    text-align: right;
}

.blurbs-and-image ul {
    padding: 0 0 0 20px;
}

.blurbs-and-image p, .blurbs-and-image li, .mobile-blurbs p, .mobile-blurbs li {
    font-weight: 200;
    margin: 0;
}

.mobile-blurbs {
    display: none;
}

.mobile-blurbs h2 {
    font-size: 8vw;
    line-height: 12vw;
    margin: 0;
}

.mobile-blurbs p, .mobile-blurbs li {
    font-size: 4vw;
    line-height: 6vw;
}

.blurbs-and-image h2 {
    margin: 20px 0 10px 0;
    font-weight: 800;
    font-size: 32px;
    line-height: 32px;
    text-transform: uppercase;
    color: var(--text);
}

.about-social-logo {
    width: 30px;
    height: 30px;
    margin-left: 12px;
}

#huntergroff .blurbs-and-image {
    z-index: 10;
    display: flex;
    width: fit-content;
    margin: 0 auto;
    height: 400px;
    align-items: flex-end;
    position:relative;
    top: -20px;
    color: var(--text);
}

.about-section {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 20% 0;
}

.about-section h4 { 
    color: var(--active);
    margin: 0;
    font-weight: 400;
    font-size: 50px;
    line-height: 50px;
}

.about-section p {
    color: var(--text);
    font-size: 20px;
    line-height: 30px;
}

@media screen and (max-width: 1200px) {
    #about .heading {
        top: -6.8vw;
        font-size: 27vw;
        line-height: 25vw;
    }
}

@media screen and (max-width: 770px) {

    #huntergroff .left-blurbs, #huntergroff .right-blurbs {
        display: none;
    }

    #huntergroff h3.mobile-name {
        display: block
    }

    #huntergroff h3.name {
        display: none;
    }

    .mobile-blurbs {
        display: block;
    }

    #huntergroff h3 {
        font-size: 11vw;
        line-height: 11vw;
        top: -2vw;
        min-width: 200px;
    }

    .about-social-logo {
        margin-left: 0;
        margin-right: 12px;
    }

    .about-section {
        padding-top: 0;
    }

    .about-section h4 {
        font-size: 10vw;
        line-height: 10vw;
    }

    .about-section p {
        font-size: 4vw;
        line-height: 6vw;
    }

    .about-headshot {
        width: 100%;
        height: auto;
        margin: 0;
    }

    #huntergroff .blurbs-and-image {
        padding: 0 50px;
        height: 90vw;
    }
}