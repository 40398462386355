#light {
    --bg: #FFFFFF;
    --bg2: #F6F6F6;
    --text: #333333;
    --interact: #29778E;
    --active: #E26200;
}

#dark {
    --bg: #222222;
    --bg2: #3A3A3A;
    --text: #FFFFFF;
    --interact: #24A7CE;
    --active: #E26200;
}

html {
    scroll-behavior: smooth;
}

body {
    width: 100%;
    overflow-x: hidden;
    overflow-y:inherit;
    background-color: var(--bg);
    transition-duration: 200ms;
    font-family: acumin-pro, sans-serif;
}

.heading {
    font-size: 200px;
    line-height: 170px;
    overflow-y: hidden;
    text-transform: uppercase;
    color: var(--text);
    font-weight: 700;
    position: relative;
    top: -40px;
    left: -25px;
    margin: 0;
    display: block;
    z-index: 0;
    transition-duration: 200ms;
}

@media screen and (max-width: 1000px) {
    .heading {
        font-size: 20vw;
        line-height: 17vw;
        top: -4vw;
    }
}

.subheading {
    font-size: 70px;
    line-height: 70px;
    text-transform: uppercase;
    color: var(--active);
    font-weight: 700;
    position: relative;
    top: -80px;
    left: 200px;
    margin: 0;
}

.container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    box-sizing: border-box;
    color: var(--text);
    transition: 200ms ease-out;
}

@media screen and (max-width: 1100px) {
    .container {
        padding: 0 50px;
        max-width: 1100px;
    }
}

a {
    text-decoration: none;
    color: var(--interact);
    transition-duration: 200ms;
}

a.active, a:hover {
    color: var(--active);
}

.sidenav {
    position: fixed;
    display: flex;
    transform: rotate(-90deg);
    width: 100vh;
    left: -47vh;
    top: 49vh;
    justify-content: space-evenly;
    z-index: 10;
}

.sidenav button {
    background: none;
    border: none;
    text-transform: uppercase;
    font-size: 14px;
    font-family: inherit;
    line-height: 14px;
    color: var(--interact);
    font-weight: 400;
    transition-duration: 200ms;
    cursor: pointer;
}

.sidenav button:hover {
    color: var(--active);
}

.section-heading-box {
    width: 100%;
    color: var(--active);
}

.section-heading {
    font-size: 36px;
    line-height: 44px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 10px 0;
}

.section-subheading {
    font-size: 18px; 
    line-height: 22px;
    font-weight: 300;
    max-width: 500px;
    margin: 0;
}