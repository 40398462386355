#resume {
    z-index: -10;
    transition-duration: 200ms;
}

.download-button {
    width: 100%;
    margin: 0;
    height: 100px;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
    top: 40px;
}

.download-text {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    margin-right: 10px;
    color: var(--active);
    transition: 200ms;
    opacity: 0;
    letter-spacing: -4px;
}

.download-icon {
    width: 40px;
    height: auto;
    color: var(--interact);
    transition: 200ms;
    cursor: pointer;
}

.download-icon:hover {
    color: var(--active);
}

.download-icon:hover + .download-text {
    opacity: 100%;
    letter-spacing: 2px;
}

.resume-section {
    padding-top: 50px;
    padding-bottom: 50px;
}

#resume h2 {
    text-transform: uppercase;
    font-size: 28px;
    line-height: 36px;
    font-weight: 800;
    letter-spacing: 4px;
    margin: 0;
    padding: 12px 0 36px 0;
    width: 100%;
    border-top: 2px solid var(--text);
}

.title-and-date {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
}

#resume h3, #resume p {
    margin: 0;
}

#resume h3, #resume p, #resume li {
    font-size: 18px;
    line-height: 26px;
}

#resume h3 {
    font-weight: 600;
}

#resume p, #resume li {
    font-weight: 300;
}

#resume p.date {
    font-style: italic;
    text-align: right;
    margin-left: 36px;
}

#resume ul {
    margin: 0;
}