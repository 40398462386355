#header {
    width: 168px;
    height: 310px;
    display: flex;
    align-items: flex-start;
    position: fixed;
    right: -132px;
    transition-duration: 200ms;
    z-index: 12;
    padding-left: 12px;
    padding-bottom: 10px;
    background-color: var(--bg);
}

#header.hide {
    right: -168px;
}

#header.expand {
    right: 0px;
}

#header .arrow-and-theme {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 25px 25px 0 0;
}

#header .arrow {
    color: var(--interact);
    stroke: var(--interact);
    width: 25px;
    height: 25px;
    margin-bottom: 2px;
    cursor: pointer;
    transition-duration: 200ms;
}

#header .arrow:hover {
    color: var(--active);
}

#header .arrow.expand {
    transform: rotate(180deg);
}

#header ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 20px;
    text-transform: uppercase;
}

#header li {
    margin: 20px 0;
}

/* Mobile: fill screen */
@media screen and (max-width: 768px) {

    #header {
        width: 100%;
        height: 100%;
        right: calc(-100% + 36px);
    }

    #header.hide {
        right: -100%;
    }

    #header ul {
        text-align: center;
        margin: auto;
        font-size: 25px;
    }

    #header.expand .arrow-and-theme {
        position: absolute;
        left: 24px;
    }
}